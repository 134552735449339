import type { DataItemModel } from 'o365.modules.DataObject.Types.ts';
import type { EventArgType } from 'o365.modules.EventEmitter.ts';

import FileUpload, { ProgressHandler } from 'o365.modules.FileUpload.ts';
import translate from 'o365.modules.translate.ts';
import { DataObject } from 'o365.modules.DataObject.ts';
import EventEmitter from 'o365.modules.EventEmitter.ts';
import { zeroBytesFilesAlert, dataURIToFile, blobToFile } from 'o365.modules.FileUpload.utils.ts';
import type { UploadOptions } from 'o365.modules.FileUpload.utils.ts';


declare module "o365.modules.DataObject.ts" {
    interface DataObject {
        _fileUpload: DataObjectFileUpload;
        fileUpload: DataObjectFileUpload
    }
}

Object.defineProperty(DataObject.prototype, "fileUpload", {
    get: function fileUpload() {
        if (!this._fileUpload) this._fileUpload = new DataObjectFileUpload(this);

        return this._fileUpload;
    }
});


class DataObjectFileUpload {
    private _dataObject: DataObject;
    private _files: Array<File> | undefined | null;
    private _fileUpload: FileUpload;
    private _records: Array<any>;
    eventHandler: EventEmitter<{}, FileUploadEvents>;
    beforeUpload: Function | undefined;
    onCompleted: Function | undefined;
    progress: ProgressHandler;
    skipRecordCreate: boolean = false;

    uploading: boolean = false;

    set files(pList) {
        if (pList && pList.constructor === FileList) {
            this._files = Array.from(pList);
        } else {
            this._files = pList;
        }
    }

    get files() {
        return this._files;
    }

    set useChunks(pValue: string) {
        this._fileUpload.useChunks = !!pValue;
    }
    set url(pValue: string) {
        this._fileUpload.url = pValue;
    }

    onAsync<K extends keyof FileUploadEvents>(event: K, listener: FileUploadEvents[K]) {
        return this.eventHandler.onAsync(event, listener);
    }

     offAsync<K extends keyof FileUploadEvents>(event: K, listener: FileUploadEvents[K]) {
        return this.eventHandler.offAsync(event, listener);
    }

    async emitAsync<K extends keyof FileUploadEvents>(event: K, ...args: EventArgType<FileUploadEvents, K>) {
        return this.eventHandler.emitAsync(event, ...args);
    }

    constructor(pDataObject: DataObject) {
        this._dataObject = pDataObject;
        this._fileUpload = new FileUpload({
            url: `/nt/api/file/upload/${this._dataObject.uniqueTable ?? this._dataObject.viewName}`,
            useChunks: true,
            viewName: this._dataObject.viewName
        });

        this.eventHandler = new EventEmitter();
        this.progress = new ProgressHandler();
        this._records = [];
    }

    async upload(pOptions: UploadOptions, pData: any) {

        if (!pOptions.files) {
            return;
        }

        if (zeroBytesFilesAlert(pOptions.files)) {
            return;
        }
        if(this.files && this.files.indexOf(pOptions.files[0]) > -1) return;

        if (typeof pOptions.files[0] === 'string') {
            pOptions.files[0] = dataURIToFile(pOptions.files[0])!;
        }

        if (pOptions.files[0] instanceof Blob && !(pOptions.files[0] instanceof File)) {
            pOptions.files = blobToFile(pOptions.files as Array<Blob>);
        }

        if (pOptions.files) {
            this.files = pOptions.files as File[];
        }

        const vUploads = [];

        let vBeforeUploadParams: any;
        if (!this._files) return Promise.resolve();
        if (!this._files.length) return Promise.resolve();
        if (this._files.length === 0) return Promise.resolve();
        this.uploading = true;
        this.progress.start(pOptions);
        this.progress.message = `${translate('Starting to upload')} ${this.files?.length} ${translate('files')}`;

        let vData: any = {};
        if (this._dataObject.masterDetails.isSet) {
            vData = Object.assign(vData, this._dataObject.masterDetails.getMasterDetailRowForInsert());
        }
        if (this.beforeUpload) vBeforeUploadParams = this.beforeUpload.call(this, ...arguments);
        if (vBeforeUploadParams === false) return Promise.resolve();
        if (vBeforeUploadParams && typeof vBeforeUploadParams == "object") {
            Object.keys(vBeforeUploadParams).forEach(key => {
                vData[key] = vBeforeUploadParams[key];
            })
        }

        vBeforeUploadParams = null;
        if (pOptions && pOptions.beforeUpload) vBeforeUploadParams = pOptions.beforeUpload.call(this, ...arguments);
        if (pOptions && vBeforeUploadParams === false) return Promise.resolve();
        if (pOptions && vBeforeUploadParams && typeof vBeforeUploadParams == "object") {
            Object.keys(vBeforeUploadParams).forEach(key => {
                vData[key] = vBeforeUploadParams[key];
            })
        }

        vBeforeUploadParams = null;
        try {
            await this.emitAsync('BeforeUploadAsync', vBeforeUploadParams);
        } catch (ex) {
            if (pOptions.onError) pOptions.onError.call(this, ...arguments);
            this.progress.message = `${translate('Error')}`;
            this.uploading = false;
            return Promise.reject(ex);
        }
        if (vBeforeUploadParams === false) return Promise.resolve();
        if (vBeforeUploadParams && typeof vBeforeUploadParams == "object") {
            Object.keys(vBeforeUploadParams).forEach(key => {
                vData[key] = vBeforeUploadParams[key];
            });
        }

        if (pData) {
            vData = Object.assign(pData, vData);
        }
        this._records = [];
        for (let i = 0; i < this._files.length; i++) {
            let vFile = this._files[i];

            let fileData = vData;

            try {
                await this.emitAsync('BeforeFileUploadAsync', vFile, fileData);
            } catch (ex) {
                if (pOptions.onError) pOptions.onError.call(this, ...arguments);
                this.progress.message = `${translate('Error')}`;
                this.uploading = false;
                return Promise.reject(ex);
            }

            vUploads.push(this.uploadFile2(vFile, fileData))

        }

        return Promise.all(vUploads).then((data: any) => {
            if (this.skipRecordCreate) {
                if (pOptions.onCompleted) pOptions.onCompleted.call(this, data, ...arguments);
                if (this.onCompleted) this.onCompleted.call(this, data, ...arguments);
            }
            if (!data || !data[data.length - 1]) return;
            this.uploading = false;

            if (!this.skipRecordCreate) {
                if (pOptions.onCompleted) pOptions.onCompleted.call(this, data, ...arguments);
                if (this.onCompleted) this.onCompleted.call(this, data, ...arguments);
            }
            this.progress.message = `${translate('Upload complete')}`;
            this._records = [];
            if (this._dataObject.dynamicLoading.enabled) {
                this._dataObject.dynamicLoading.dataLoaded(this._dataObject.storage.data, { skip: 0 });
            }
            if (data.length === 1)
                if (data[0].dataObjectId) {
                    this._dataObject.setCurrentIndexByPrimKey(data[0].primKey);
                } else {
                    this._dataObject.setCurrentIndexByPrimKey(data[data.length - 1][0][0]['PrimKey']);
                }
            this.progress.completeUpload();
            return data;
        }).catch((e) => {
            //wht to do ?
            console.error(e);
            if (pOptions.onError) pOptions.onError.call(this, ...arguments);
            this.progress.message = `${translate('Error')}`;
            this.uploading = false;
        });


    }

    cancel() {
        this.uploading = false;
        this.files = null;
        this.progress.message = `${translate('Canceling upload')}`;
        this._records.forEach(rec => {
            if (rec.primKey) {
                this._dataObject.cancelChanges(rec.item.index);
            } else {
                this._dataObject.deleteItem(rec.item);
            }
        });
        this._records = [];
        this._fileUpload.abort();
    }

    async createOrUpdateRecord(pFile: File, pData: any) {
        if (pData.hasOwnProperty("PrimKey")) {
            this.progress.message = `${translate('Updating record')}: ${pFile.name} `;
        } else {
            this.progress.message = `${translate('Creating record')}: ${pFile.name}`;
        }

        const vData = Object.assign(pData, {
            FileName: pFile.name,
            FileSize: pFile.size,

        });
        let vRow: DataItemModel | undefined = undefined;
        if (pData.hasOwnProperty("PrimKey")) {
            if (this._dataObject.batchDataEnabled) {
                vRow = this._dataObject.storage.getItemByPrimKey(pData["PrimKey"]) ?? this._dataObject.batchData.storage.getItemByPrimKey(pData["PrimKey"])
            } else {
                vRow = this._dataObject.storage.getItemByPrimKey(pData["PrimKey"])
            }
            if (vRow == null) {
                throw new TypeError(`Cannot find item with ${pData['PrimKey']} PrimKey in storage for update`);
            }
            //will be update operatrion
            if (vRow.index < 0) {
                this._dataObject.batchData.storage.updateItem(this._dataObject.batchData.getInversedIndex(vRow.index), vData, false);
            } else {
                this._dataObject.storage.updateItem(vRow.index, vData, false);
            }
            this._records.push({
                item: vRow,
                primKey: pData["PrimKey"]
            });
            return vRow;
            //   return Promise.resolve(vRow);
        } else {
            //create            
            const options = {
                ...this._dataObject.recordSource.getOptions(),
                uniqueTable: this._dataObject.uniqueTable,
                values: pData,
            }
            return this._dataObject.dataHandler.create(options).then((pRow) => {
                if (pData.index != null) {
                    // get real row and update it
                    vRow = pData.index < 0
                        ? this._dataObject.batchData.storage.updateItem(this._dataObject.batchData.getInversedIndex(pData.index), pRow[0], true)
                        : this._dataObject.storage.updateItem(pData.index, pRow[0], true);
                } else {
                    vRow = this._dataObject.createNew(pRow[0], false);
                    vRow.reset();
                    vRow.state.isNewRecord = false;
                    this._records.push({
                        item: vRow,
                        primKey: null
                    });
                }
                return vRow;
            });

        }
        return vRow.save();
    }

    async uploadFile2(pFile: File, pData: any) {
        try {
            const vRef = await this._uploadFile2(pFile, pData);
            pData["FileRef"] = vRef['FileRef'];
            this.progress.message = `${translate('Uploading file: ')} ${pFile.name}`;
            if (this.skipRecordCreate) return { ...pData, ...vRef };
            return this.createOrUpdateRecord(pFile, pData).then((row) => {
                return row;
            }).catch(ex => {
                //record update failed
                console.error(ex);
                this.progress.message = `${translate('Error')}`;
                // this.progress.completeUpload();
                this.cancel();
                this.progress.error = ex;
                return Promise.reject(ex);
            });
        } catch (ex: any) {
            this.progress.updateError(pFile, ex?.message ?? ex ?? translate('Upload failed'));
        }

    }

    private async _uploadFile2(pFile: File, pData: any) {
        return await this._fileUpload.upload({
            file: pFile,
            data: pData,
            //   uploadRef: pPrimKey,
            onProgress: (e: ProgressEvent) => this.progress.updateProgress(pFile, e)
        })
    }

}

type FileUploadEvents = {
    "BeforeUploadAsync": (pBeforeUploadParams: object) => Promise<object>,
    "BeforeFileUploadAsync": (pFile: File, fileData: object) => Promise<object>,
}

export { DataObjectFileUpload };

